import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

const chevron = `<svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>`;
const loadingSpinner = `<div style="border-top-color:transparent" class="w-5 h-5 border-4 border-grey-400 border-solid rounded-full animate-spin"></div>`
export default class extends Controller {
    static targets = ['select'];
    static values = {
        url: String
    }

    async performQuery(e) {
        e.preventDefault();
        const courseName = e.target.dataset.filterValue;
        this.selectTarget.innerHTML = loadingSpinner;
        
        await get(this.urlValue, {
            query: {
                course_name: courseName
            },
            responseKind: 'turbo-stream'
        });

        const text = courseName === "all" ? "Filter options" : courseName;
        this.selectTarget.innerHTML = `${text} ${chevron}`;
    }
}