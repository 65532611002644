import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

const chevron = `<svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>`;
const loadingSpinner = `<div style="border-top-color:transparent" class="w-5 h-5 border-4 border-grey-400 border-solid rounded-full animate-spin"></div>`
export default class extends Controller {
    static targets = ['selectCourseName', 'selectFinished', 'selectTrackerOption', 'query'];
    static values = {
        url: String
    }

    initialize() {
        this.courseName = 'all';
        this.statusFilter = 'All';
        this.statusTrackerOption = 'All';
    }

    getCourseNameValue(e) {
        e.preventDefault();
        this.courseName = e.target.dataset.filterValue;

        this.performQuery();
    }

    getFinishedValue(e) {
        e.preventDefault();

        if(this.statusFilter == 'All'){
            this.statusFilter = []
            this.statusFilter.push(e.target.dataset.filterValue);
        }
        else{
            this.statusFilter.push(e.target.dataset.filterValue);
        }

        this.performQuery();
    }

    getTrackerOptionValue(e) {
      e.preventDefault();
      this.statusTrackerOption = e.target.dataset.filterValue;

      this.performQuery();
    }

    async submit(e) {

      const value = this.queryTarget.value
      e.preventDefault();
 
      await get(this.urlValue, { 
        query: {
          query: value
        },
        responseKind: 'turbo-stream'
      });
    }

    async performQuery() {
        this.selectCourseNameTarget.innerHTML = loadingSpinner;
        this.selectFinishedTarget.innerHTML = loadingSpinner;
        this.selectTrackerOptionTarget.innerHTML = loadingSpinner;

        await get(this.urlValue, {
            query: {
                course_name: this.courseName,
                status_filter: this.statusFilter,
                tracker_option: this.statusTrackerOption
            },
            responseKind: 'turbo-stream'
        });
        
        const text = this.courseName === 'all' ? 'Course' : this.courseName;
        const finishedText = this.statusFilter === 'All' ? 'Athlete' : this.statusFilter.length;
        const finish = this.statusTrackerOption === 'All' ? 'Tracker' : this.statusTrackerOption;
        
        this.selectCourseNameTarget.innerHTML = `${text} ${chevron}`;
        this.selectFinishedTarget.innerHTML = `${finishedText} selected ${chevron}`;
        this.selectTrackerOptionTarget.innerHTML = `${finish} ${chevron}`;
    }
}