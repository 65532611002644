import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tBody'];
  
  toggleSelect(e) {
    const isChecked = e.target.checked;
    const rows = this.tBodyTarget.getElementsByTagName('tr');
    for (let row of rows) {
      const checkboxes = row.children[0].getElementsByTagName('input');
      for (let checkbox of checkboxes) {
        checkbox.checked = isChecked;
      }
    }
    
  }
}