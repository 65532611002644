// app/javascript/packs/alerts.js

document.addEventListener("DOMContentLoaded", function () {
    const closeButton = document.querySelector("[data-dismiss-target]");

    if (closeButton) {
        closeButton.addEventListener("click", function () {
            const alert = document.querySelector(this.getAttribute("data-dismiss-target"));

            if (alert) {
                alert.remove();
            }
        });
    }
});
