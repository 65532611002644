import {
  Controller
} from 'stimulus';
import {
  patch
} from '@rails/request.js';

export default class extends Controller {
  static targets = ['form', 'hexColor', 'image', 'emergencyContacs', 'showAllNames', 'norseman'];
  static values = {
    url: String,
    raceLegacyId: String
  }

  autosave(e) {
    e.preventDefault();
    const formData = new FormData(this.formTarget);

    patch(this.urlValue, {
      responseKind: 'turbo-stream',
      body: formData
    })
  }
}