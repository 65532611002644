import {
  Controller,
} from 'stimulus';
import {
  post,
} from '@rails/request.js';

const loadingSpinner = `<div style="border-top-color:transparent" class="w-5 h-5 border-4 border-grey-400 border-solid rounded-full animate-spin"></div>`;

export default class extends Controller {
  static targets = [
    'tBody',
    'intervalInput',
    'sleepIntervalInput',
    'dateInput',
    'selectInput',
    'alarmInput',
    'timeZoneInput'];
  static values = {
    armUrl: String,
    disarmUrl: String,
    intervalUrl: String,
    sleepIntervalUrl: String,
    programUrl: String,
    cancelUrl: String,
    configActionUrl: String,
  };
  
  async performArm(e) {
    e.preventDefault();
    const trackerIds = this._getSelectedTrackers();
    
    if (trackerIds.length) {
      e.target.innerHTML = loadingSpinner;
      await post(this.armUrlValue, {
        body: {
          tracker_ids: trackerIds,
        },
      });
      e.target.innerHTML = 'Arm';
      this._removeSelectedTrackers();
    }
  }
  
  // Perform Disarm
  async performDisarm(e) {
    e.preventDefault();
    const trackerIds = this._getSelectedTrackers();
    
    if (trackerIds.length) {
      e.target.innerHTML = loadingSpinner;
      await post(this.disarmUrlValue, {
        body: {
          tracker_ids: trackerIds,
        },
      });
      e.target.innerHTML = 'Disarm';
      this._removeSelectedTrackers();
    }
  }
  
  async configActionDisarm() {
    try {
      const trackerIds = this._getSelectedTrackers();
      const object = {tracker_ids: trackerIds, actions: 'disarm'};
      this._configActionRequest(object);
    } catch (error) {
      console.error(error);
    }
  }
  
  async configActionArm() {
    try {
      const trackerIds = this._getSelectedTrackers();
      const object = {tracker_ids: trackerIds, actions: 'arm'};
      this._configActionRequest(object);
    } catch (error) {
      console.error(error);
    }
  }
  
  async configActionInterval() {
    try {
      const trackerIds = this._getSelectedTrackers();
      const sleepInterval = this.intervalInputTarget.value || 0;
      
      const object = {
        tracker_ids: trackerIds,
        interval: sleepInterval,
        actions: 'interval',
      };
      this._configActionRequest(object);
    } catch (error) {
      console.error(error);
    }
  }
  
  async configActionSleepInterval() {
    try {
      const trackerIds = this._getSelectedTrackers();
      const sleepInterval = this.sleepIntervalInputTarget.value || 0;
      
      const object = {
        tracker_ids: trackerIds,
        sleep_interval: sleepInterval,
        actions: 'sleepinterval',
      };
      this._configActionRequest(object);
    } catch (error) {
      console.error(error);
    }
  }
  
  async performProgramAction(e) {
    e.preventDefault();
    
    const trackerIds = this._getSelectedTrackers();
    const time = this.dateInputTarget.value;
    const action = this.selectInputTarget.value;
    const timeZone = this.timeZoneInputTarget.value;
    
    if (trackerIds.length && action && time) {
      e.target.innerHTML = loadingSpinner;
      await post(this.programUrlValue, {
        body: {
          tracker_ids: trackerIds,
          time: time,
          action: action,
          time_zone: timeZone,
        },
        responseKind: 'turbo-stream',
      });
      e.target.innerHTML = 'Programmed';
    }
  }
  
  async performProgramCancel(e) {
    e.preventDefault();
    
    const trackerIds = this._getSelectedTrackers();
    const alarmId = this.alarmInputTarget.value;
    
    if (trackerIds.length) {
      e.target.innerHTML = loadingSpinner;
      await post(this.cancelUrlValue, {
        body: {
          tracker_ids: trackerIds,
          program_tracker_id: alarmId,
        },
        responseKind: 'turbo-stream',
      });
      e.target.innerHTML = 'Canceled';
    }
  }
  
  async setInterval(e) {
    e.preventDefault();
    
    const trackerIds = this._getSelectedTrackers();
    
    if (trackerIds.length) {
      e.target.innerHTML = loadingSpinner;
      await post(this.intervalUrlValue, {
        body: {
          tracker_ids: trackerIds,
          interval: this.intervalInputTarget.value || 0,
        },
      });
      e.target.innerHTML = 'Set';
      this.intervalInputTarget.value = null;
      this._removeSelectedTrackers();
    }
  }
  
  async setSleepInterval(e) {
    e.preventDefault();
    
    const trackerIds = this._getSelectedTrackers();
    
    if (trackerIds.length) {
      e.target.innerHTML = loadingSpinner;
      await post(this.sleepIntervalUrlValue, {
        body: {
          tracker_ids: trackerIds,
          sleep_interval: this.sleepIntervalInputTarget.value || 0,
        },
      });
      e.target.innerHTML = 'Set';
      this.sleepIntervalInputTarget.value = null;
      this._removeSelectedTrackers();
    }
  }
  
  _getSelectedTrackers() {
    let ids = [];
    const rows = this.tBodyTarget.getElementsByTagName('tr');
    
    for (let row of rows) {
      const checkboxes = row.children[0].getElementsByTagName('input');
      for (let checkbox of checkboxes) {
        if (checkbox.checked) {
          ids.push(parseInt(checkbox.value));
        }
      }
    }
    
    console.log(
      '🚀 ~ file: trackers_commands_controller.js ~ line 141 ~ extends ~ _getSelectedTrackers ~ ids',
      ids);
    return ids;
  }
  
  _removeSelectedTrackers() {
    const selectedItems = document.querySelectorAll(
      'input[type="checkbox"]:checked');
    
    selectedItems.forEach(item => {
      item.checked = false;
    });
    
    console.log(
      '🚀 ~ file: trackers_commands_controller.js ~ line 181 ~ extends ~ _removeSelectedTrackers ~ ids',
      selectedItems.length);
  }
  
  async _configActionRequest(object) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').
      getAttribute('content');
    
    const response = await fetch(this.configActionUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify(object),
    });
    if (response.ok) {
      console.log('Response', object['actions'], ' ', response.ok);
    } else {
      throw new Error('Error en la solicitud');
    }
  }
}