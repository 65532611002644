import { Controller } from 'stimulus';  

export default class extends Controller {
    static targets = ['tabs', 'form'];
    static values = {
        updateUrl: String
    }

    initialize() {
        this.isForVisible = false;
    }

    toggleSections(event) {
        event.preventDefault();
        
        this.isForVisible = !this.isForVisible;
        if (this.isForVisible) {
            this.tabsTarget.classList.add('hidden');
            this.formTarget.classList.remove('hidden');
        } else {
            this.tabsTarget.classList.remove('hidden');
            this.formTarget.classList.add('hidden');
        }
    }

    upsertRace(event) {
        event.preventDefault();
    }
}